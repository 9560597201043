<template>
  <section>
    <b-modal
      id="modal-success-broken-item-submission"
      body-class="p-1"
      hide-header
      hide-footer
      centered
      no-close-on-backdrop
      no-close-on-esc
    >
      <b-overlay
        variant="light"
        spinner-variant="primary"
        blur="0"
        opacity=".5"
        rounded="sm"
      >
        <div class="modal-success d-flex flex-column justify-content-center">
          <div class="d-flex w-100 justify-content-end">
            <a
              href="#"
              @click="$emit('close')"
            >
              <img
                src="@/assets/images/icons/close-circle.svg"
                height="18"
                width="18"
                alt="close"
                class="float-right"
              />
            </a>
          </div>
          <b-row class="justify-content-center mb-1 pt-1">
            <b-img src="@/assets/images/icons/success.svg" />
          </b-row>
          <b-row class="text-center px-4 mb-1 justify-content-center">
            <p class="text-10 font-bold text-dark">
              Pengajuan berhasil dikirim
            </p>
          </b-row>
          <b-row class="text-center px-4 mb-1 justify-content-center">
            <p class="text-dark text-8">
              Memerlukan persetujuan partner, hubungi partner untuk mengetahui
              info lebih lanjut
            </p>
          </b-row>
          <b-row class="justify-content-center pb-1 gap-20">
            <button
              class="text-center button-secondary px-[45px] py-[6px]"
              variant="secondary"
              @click="$emit('close')"
            >
              Kembali
            </button>
            <button
              class="text-center button-primary px-[12px] py-[8px]"
              variant="primary"
              @click="$emit('close')"
            >
              <a :href="`https://wa.me/${phone}`" target="_blank" class="text-white">Hubungi Partner</a>
            </button>
          </b-row>
        </div>
      </b-overlay>
    </b-modal>
  </section>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  props: {
    detailOutbound: Object,
  },
  data() {
    return {
      fields: [
        { key: 'product_name', label: 'Nama Produk', tdClass: 'text-left' },
        { key: 'variant', label: 'Variasi', tdClass: 'text-left' },
        { key: 'stock', label: 'Stok' },
        { key: 'total_submission', label: 'Jumlah Pengajuan' },
      ],
    }
  },
  computed: {
    ...mapFields('brokenItemSubmission', ['phone']),
  },
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
@import '@core/scss/vue/libs/vue-select.scss';

$primary: #f95031;
$orange: #ff6a3a;
$grayscale: #828282;
$black--text: #222222;

.container {
  font-family: 'Poppins', sans-serif;
}

.button-secondary {
  background-color: #fff;
  border: 1px solid $primary;
  color: $primary;
  border-radius: 8px;
  font-weight: 500;
}

.button-primary {
  background-color: $primary;
  border: 1px solid #fff;
  color: #fff;
  border-radius: 8px;
  font-weight: 500;
}

.text {
  @for $i from 1 through 16 {
    &-#{$i} {
      font-size: #{$i * 2}px;
    }
  }
}

.text-dark {
  color: #222222 !important;
}

.gap {
  @for $i from 1 through 32 {
    &-#{$i} {
      gap: #{$i}px;
    }
  }
}

.status {
  border-radius: 20px;
  padding: 1px 10px;

  &-submit {
    color: #fba63c;
    background-color: #fcebbe;
  }

  &-process {
    color: #4285f4;
    background-color: #dff3ff;
  }

  &-completed {
    color: #34a770;
    background-color: #befcde;
  }
}
</style>
