const dataOutbound = [
  {
    key: 'date',
    label: 'Tanggal',
    thClass: 'text-capitalize text-dark text-2 text-left',
    tdClass: 'text-left',
  },
  {
    key: 'reason',
    label: 'Alasan',
    thClass: 'text-capitalize text-dark text-2 text-left',
    tdClass: 'text-left',
  },
  {
    key: 'partner',
    label: 'Partner',
    thClass: 'text-capitalize text-dark text-2 text-left',
    tdClass: 'text-left',
  },
  {
    key: 'quantity',
    label: 'Jumlah Produk',
    thClass: 'text-capitalize text-dark text-2 text-left',
    tdClass: 'text-left',
  },
  {
    key: 'status',
    label: 'Status',
    thClass: 'text-capitalize text-dark text-2',
    tdClass: 'text-center',
  },
  {
    key: 'detail',
    label: 'Action',
    thClass: 'text-capitalize text-dark text-2 text-left',
  },
]

const waitingResponse = [
  {
    key: 'date',
    label: 'Tanggal',
    thClass: 'text-capitalize text-left',
    tdClass: 'text-left',
  },
  {
    key: 'reason',
    label: 'Alasan',
    thClass: 'text-capitalize text-left',
    tdClass: 'text-left',
  },
  {
    key: 'warehouse',
    label: 'Partner',
    thClass: 'text-capitalize text-left',
    tdClass: 'text-left',
  },
  {
    key: 'quantity',
    label: 'Jumlah Produk',
    thClass: 'text-capitalize text-left',
    tdClass: 'text-left',
  },
  {
    key: 'detail',
    label: 'Action',
    thClass: 'text-capitalize text-left',
  },
]

const submissionTable = [
  {
    key: 'checkbox',
    label: '',
    tdClass: 'align-top',
  },
  {
    key: 'product_name',
    label: 'Nama Produk',
    tdClass: 'align-top text-left',
    thClass: 'text-capitalize text-left',
  },
  {
    key: 'variant',
    label: 'Variasi',
    tdClass: 'align-top text-left',
    class: 'td-variant',
    thClass: 'text-capitalize text-left',
  },
  {
    key: 'stock',
    label: 'Stok',
    tdClass: 'align-top text-left',
    thClass: 'text-capitalize text-left',
  },
  {
    key: 'fulfillment_costs',
    label: 'Biaya Fulfillment',
    tdClass: 'align-top text-left',
    thClass: 'text-capitalize text-left',
  },
  {
    key: 'total_submission',
    label: 'Jumlah Pengajuan',
    tdClass: 'align-top text-left',
    thClass: 'text-capitalize text-left',
  },
]

const fieldBarangRusak = [
  {
    key: 'product_name',
    label: 'Nama Produk',
    thClass: 'bg-[#F4F4F4] text-left text-capitalize !text-black',
    tdClass: 'text-black text-left align-top',
  },
  {
    key: 'variants',
    label: 'Variasi',
    thClass: 'bg-[#F4F4F4] text-left text-capitalize !text-black',
    tdClass: 'text-black text-left space-y-3 align-top',
  },
  {
    key: 'barang_rusak',
    label: 'Barang Rusak',
    thClass: 'bg-[#F4F4F4] text-capitalize !text-black',
    tdClass: 'text-black space-y-3 align-top',
  },
]

export {
  dataOutbound, waitingResponse, submissionTable, fieldBarangRusak,
}
