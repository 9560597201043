import { mapState, mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import { numberOnly } from '@/libs/helpers'
import modal from '../modal/modalSuccess.vue'
import { submissionTable, fieldBarangRusak } from '../tableconfig'

export default {
  components: {
    modal,
    vSelect,
  },
  data() {
    return {
      fieldBarangRusak,
      fields: submissionTable,
      selectAll: false,
      selected: [],
      barangRusak: [],
      loading: false,
      memo: '',
      disabledKey: true,
      error: 'Minimal 10 karakter',
      numberOnly,
    }
  },
  mounted() {
    this.$store.commit('brokenItemSubmission/RESET_PRODUCTS')
    this.partnerSelected = ''
    this.$store.dispatch('brokenItemSubmission/init')
      .catch(err => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failure',
            icon: 'AlertCircleIcon',
            text:
              'Unable to load the table data. Please try again later or contact support.',
            variant: 'danger',
          },
        })
      })
  },
  computed: {
    ...mapFields('brokenItemSubmission', ['partnerSelected']),
    ...mapGetters('brokenItemSubmission', ['listPartners', 'dataProductsMod']),
  },
  methods: {
    async selectPartner() {
      this.loading = true
      this.selected = []
      await this.$store
        .dispatch('brokenItemSubmission/getListProductByPartner')
        .then(() => { })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text:
                'Unable to load the table data. Please try again later or contact support.',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    showModalAjukan() {
      const nonVariantFiltered = this.selected.filter(product => (product.is_variant === 0 && product.total > 0))
      const variantFiltered = this.selected.filter(product => (product.is_variant === 1)).map(product => ({
        ...product,
        variants: product.variants.filter(item => item.total > 0),
      }
      ))

      this.barangRusak = [...nonVariantFiltered, ...variantFiltered]
      this.$bvModal.show('modal-barang-rusak')
    },
    submission() {
      const data = {
        products: this.barangRusak,
        memo: this.memo,
      }
      this.$store.dispatch('brokenItemSubmission/submission', data)
        .then(() => {
          this.$bvModal.show('modal-success-broken-item-submission')
        })
        .catch(err => {
          if (err?.products[0] === 'The products field is required.') {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Failure',
                icon: 'AlertCircleIcon',
                text: 'Jumlah pengajuan kosong',
                variant: 'danger',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Failure',
                icon: 'AlertCircleIcon',
                text: err,
                variant: 'danger',
              },
            })
          }
        })
    },
    addQty(isVariant, indexRow, indexVariant) {
      if (isVariant === 1) {
        this.dataProductsMod[indexRow].variants[indexVariant].total += 1
      } else {
        this.dataProductsMod[indexRow].total += 1
      }
      this.disabledKey = false
    },
    minQty(isVariant, indexRow, indexVariant) {
      if (isVariant === 1) {
        this.dataProductsMod[indexRow].variants[indexVariant].total -= 1
      } else {
        this.dataProductsMod[indexRow].total -= 1
      }
      this.disabledKey = false
    },
    checkedAll() {
      if (this.selectAll) {
        this.selected = this.dataProductsMod
      } else {
        this.selected = []
      }
    },
    handleCheckbox() {
      if (this.selected.length === this.dataProductsMod.length) {
        this.selectAll = true
      } else {
        this.selectAll = false
      }
    },
    onCloseModal() {
      this.$bvModal.hide('modal-success-broken-item-submission')
      this.$router.push('/outbound/pengeluaran-barang')
    },
    checkData() {
      const nonVariantFiltered = this.selected.filter(product => (product.is_variant === 0 && product.total > 0))
      const variantFiltered = this.selected.filter(product => (product.is_variant === 1)).map(product => (product.variants.filter(item => item.total > 0)))

      if (this.selected.length === 0) return true
      if ((nonVariantFiltered?.length + variantFiltered?.length) === this.selected.length && variantFiltered.every(data => data.length > 0)) return false
      if ((variantFiltered?.length) === this.selected.length && variantFiltered.every(data => data.length > 0)) return false
      if (nonVariantFiltered?.length > 0 && (nonVariantFiltered?.length) === this.selected.length) return false

      return true
    },
    formatTextArea(value) {
      if (value.length >= 256) {
        this.error = 'Maksimal 255 karakter'
        return value.slice(0, 256)
      }
      if (value.length < 10) {
        this.error = 'Minimal 10 karakter'
      } else {
        this.error = null
      }
      return value
    },
    limitToStock(isVariant, total, stock, index, varIndex) {
      if (isVariant === 1) {
        this.dataProductsMod[index].variants[varIndex].total = total > stock ? stock : total
      } else {
        this.dataProductsMod[index].total = total > stock ? stock : total
      }
      this.disabledKey = false
    },
  },
}
